import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout";
import Navbar from "../components/Navbar";

// markup
const NotFoundPage = ({location}) => {
  return (
    <Layout location={location} showHeader={true}>
      <title>CalPERS Benefits Education Event | CalPERS</title>

      <Navbar location={location} />

      <div className="overflow-hidden">
        <div className="h-auto bg-cover relative bg-brand-900">
         

          <div
            className="relative bg-gray-100"

          >
            <div
              className={`max-w-7xl py-8 carousel-content-container flex flex-wrap justify-between relative mx-auto px-4 md:px-6 pb-64`}
            >
              <div className="w-full py-2 md:py-4 md:pr-8 lg:pr-32 relative ">
                <div className="max-w-2xl">
                  <span className="text-center md:text-left block font-semibold my-2 text-lg text-gray-500 tracking-wide uppercase service-heading-tag">
                   404
                  </span>
                  <h1 className="text-center md:text-left text-black relative mb-2 text-2xl md:text-4xl font-bold leading-10">
                   Page Not Found
                  </h1>
                  <p className="text-center md:text-left block font-medium my-2 text-base text-gray-700 ">
                  We updated our website and the information you're looking for is no longer located here.  To continue, return to the previous page, go to our home page.


                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      </Layout>
  )
}

export default NotFoundPage
